import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Image from '@/components/utils/Image.vue';

import { createCookieConsent } from '@teamnovu/kit-cookieconsent';
import '@teamnovu/kit-cookieconsent/style'; // eslint-disable-line

import store from './store';
import checkView from './utils/vue-check-view';

import './assets/styles/main.scss';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);

app.use(store);
app.use(VueScrollTo);
app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });

if (window) {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    // Rest params (...args) DOES NOT WORK HERE!
    // For some reason, the arguments array includes a gtm.uniqueEventId that would be missing from ...args
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };
  window.gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  });
}

const cookieConsent = createCookieConsent({
  guiOptions: {
    consentModal: {
      layout: 'box wide',
      equalWeightButtons: true,
    },
  },

  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    functional: {
      enabled: false,
      readOnly: false,
      services: {
        youtube: { label: 'Youtube Embed' },
      },
    },
    analytics: {
      enabled: false,
      readOnly: false,
      services: {
        googleAnalytics: { label: 'Google Analytics' },
      },
      autoClear: {
        cookies: [{ name: /^_/ }],
      },
    },
  },

  language: {
    default: 'en',
    translations: {
      en: {
        consentModal: {
          title: 'We use cookies',
          description:
          // eslint-disable-next-line max-len
            'We use cookies to improve the user experience on our website, analyze our traffic, personalize content, and provide social media features.<br /><br />Learn more in our <a href="en/landingpages/privacy-policy">Privacy Policy</a>.',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage Individual preferences',
        },
        preferencesModal: {
          title: 'Manage cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Accept current selection',
          closeIconLabel: 'Close',
          sections: [
            {
              title: 'Cookie preferences',
              description:
              // eslint-disable-next-line max-len
                'We use cookies to ensure the basic functions of the website and to improve your online experience. You can choose to opt in or out of each category whenever you want. For more details about cookies and other sensitive data, please see the full <a href="en/landingpages/privacy-policy" class="cc-link">Privacy Policy</a>',
            },
            {
              title: 'Strictly Necessary cookies',
              description:
                'These cookies are essential for the proper functioning of the website and cannot be disabled.',
              linkedCategory: 'necessary',
              cookieTable: {
                headers: {
                  name: 'Name',
                  description: 'Description',
                  expiration: 'Validity',
                },
                body: [
                  {
                    name: 'CC Cookie',
                    description: 'Cookie preferences',
                    expiration: '6 Months',
                  },
                ],
              },
            },
            {
              title: 'Functional cookies',
              description:
              // eslint-disable-next-line max-len
                'These cookies enable extended use of the website, such as loading videos that are integrated via third-party providers or sending protected forms.',
              linkedCategory: 'functional',
            },
            {
              title: 'Performance and Analytics',
              description:
              // eslint-disable-next-line max-len
                'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
              linkedCategory: 'analytics',
            },
          ],
        },
      },
    },
  },
});

app.use(cookieConsent);
window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgUGF0cmljayE');

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);

app.mount('#app');
if (!window.prerender) setTimeout(() => window.document.body.classList.remove('no-anim'), 100);
